* {
    box-sizing: border-box;
    flex-wrap:  wrap;
}

body, html {
    font-size: $base-font-size;
    height: 100%;
    margin: 0;
    color: $text-color;
    font-family: $font-serif;
}

a {
    text-decoration: none;
    color: $link-color;
}

p a:hover {
    text-decoration: underline;
}


h1, h2, h3, h4, h5, h6 {
    font-family: $font-sans;
    font-weight: 700;
    margin-bottom: .5em;

    &:first-child {
      margin-top: 0;
    }
}
h2 {
    font-size: 1.5em;
}

p {
    line-height: 1.5em;
}

b, strong {
    font-weight: bold;
}

i, em {
    font-style: italic;
}

pre, code {
    font-family: mono;
    background: #f0f0f0;
    font-size: .8em;
    overflow-x: auto;
}

hr {
    border-width: 1px 0 0;
    border-color: #ccc;
    margin: 1rem 0;
}

label {
    font-family: $font-sans;
    font-size: .75em;
    font-weight: bold;
}

small, .small { font-size: 85% }
