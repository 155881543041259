

/* Forms
----------------- */

.input-group {
    display: inline-flex;

    .field {
        flex: 1;
    }

    > .input, > .button, > .input-addon {
        &:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        }
    }

    > .input, > .button, > .input-addon {
        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-right: left;
        }
    }
}


.input, .button, .textarea, .input-addon {
    color: $text-color;
    line-height: $line-height;
    font-size: .75em;
    display: inline-block;
    padding: .5em .75em;

    border: 1px solid #ccc;
    border-radius: .25em;

    &:focus {
        border-color: #23d160;
    }
}
.button, .input-addon {
    /* background: #ddd; */
    background: #f3f3f3;
    border-width: 1px;
    font-family: $font-sans;

    &:hover {
        background: #dadada;
        cursor: pointer;
        text-decoration: none;
    }

    &.is-discreet {
        color: inherit;
        background: transparent;
        border-left-width: 0px;
        border-right-width: 0px;

        &:hover {
            color: $link-color;
        }
    }
}
.button:hover {
    border-color: lighten($text-color, 40);
}
select.input {
    background: inherit;
}
