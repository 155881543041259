
/* Links modifiers
----------------- */

// Underlining
a.underline-hover, .underline-hover a {
    text-decoration: none;
    &:hover{
        text-decoration: underline;
    }
}

// links that don't look like links
a.discreet-link, .discreet-link a {
    color:  inherit;
    text-decoration: inherit;
}


/* Sizing
----------------- */
body .is-small {
    font-size: .75rem;
}


/* Coloring
----------------- */

body .is-info {
    // er-color: $info;
    boder-color: $info;
}
body a.is-info:hover, body .button.is-info:hover {
    background-color: $info-slight;
    color: $info;
    border-color: $info;
}

body .is-success {
    color: $success;
    border-color: $success;
}
body a.is-success:hover, body .button.is-success:hover {
    background-color: $success-slight;
    color: $success;
    border-color: $success;
}

body .is-danger {
    color: $danger;
    border-color: $danger;
}
body a.is-danger:hover, body .button.is-danger:hover {
    background-color: $danger-slight;
    color: $danger;
    border-color: $danger;
}

body .is-warning {
    color: $warning;
    border-color: $warning;
}
body a.is-warning:hover, body .button.is-warning:hover {
    background-color: $warning-slight;
    color: $warning;
    border-color: $warning;
}

/* buttons that look like buttons only when they are hovered
body a.is-hover-only, body .button.is-hover-only {
    border-color: transparent;
    background-color: transparent;
    color: $link-color;
    &:hover {
        border-color: $grey-90;
        background-color: $grey-90;
    }
}
*/
