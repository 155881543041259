$base-font-size: 1rem;
$font-serif: "Droid Serif", georgia, serif;
$font-sans: "Droid Sans", arial, sans-serif;
$font-mono: monospace;
$line-height: 1.5em;

// COLORS
// ==============================================

// by name

/* $black:   #000;
$white:   #fff;
$grey-00: $black;
$grey-10: #1A1A1A;
$grey-20: #333;
$grey-30: #4D4D4D;
$grey-40: #666;
$grey-50: #7F7F7F;
$grey-60: #999;
$grey-70: #B3B3B3;
$grey-80: #ccc;
$grey-90: #E5E5E5;
$grey-100: $white; */

// by concept

$info: #23d160;
$info-slight: desaturate( lighten($info, 50%), 50% );
// $info-heavy: darken($info, 80);

$success: green;
$success-slight: desaturate( lighten($success, 50%), 50% );
// $success-heavy: darken($success, 80);

$warning: #E67609;
$warning-slight: desaturate( lighten($warning, 50%), 50% );
// $warning-heavy: darken($warning, 80);

$danger: #E60909;
$danger-slight: desaturate( lighten($danger, 50%), 50% );
// $danger-heavy: darken($danger, 80);

$admin: #E60909;

// by use

$text-color: #333;
$note-color: #aaa;
$link-color: #08C;
