

/* Utilities
----------------- */

// sizing
.flex { display: flex; }
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-1 { flex: 1; }
.full-width { width: 100%; }

// spacing
.space-above          { margin-top:       1rem; }
.space-above-small    { margin-top:       .5rem; }
.space-above-large    { margin-top:       .2rem; }
.space-under          { margin-bottom:    1rem; }
.space-under-small    { margin-bottom:    .5rem; }
.space-under-large    { margin-bottom:    2rem; }
.space-left           { margin-left:      1rem; }
.space-right          { margin-right:     1rem; }
/*
.horizontal-space-between-small > * {
    &:not(:first-child) { margin-left:  .25rem; }
    &:not(:last-child)  { margin-right: .25rem; }
}
.vertical-space-between-small > * {
    &:not(:first-child) { margin-top:    .25rem; }
    &:not(:last-child)  { margin-bottom: .25rem; }
}
*/
.space-between { justify-content: space-between; }

// styling
.list-unstyled {
    padding:         0;
    list-style:     none;
}
.list-inline {
    display: block;

    & > .list-item {
        display: inline-block;
    }
}
.serif        { font-family: $font-serif; }
.sans-serif   { font-family: $font-sans; }
.monospace    { font-family: $font-mono; }
.align-right  { text-align: right; }
.align-center { text-align: center; }
.align-left   { text-align: left; }
.note { font-size: .8em; color: $note-color }

// misc
.hidden { display: none; }
.float-left { float: left; }
.float-right { float: right; }
.enticon { // icons in the shape of htmlentities.
  // see http://www.amp-what.com/ for available entities
  font-size: 1.5em;
  position: relative;
  top: .1em;
  line-height: 0;
}
