.notification {
    padding: 1.25rem;
    border-radius: .25rem;
    background-color: #ccc;
    color: white;
    font-family: sans-serif;
}
.notification:not(:last-child) {
    margin-bottom: 1.5rem;
}

.notification.is-success {
  background-color: $success-slight;
}

.notification.is-danger {
  background-color: $danger-slight;
}
