.pre {
  border: 1px solid $note-color;
  background: #fafafa;
  padding: 1em;
}

.code {
    border: 1px solid $note-color;
    background: #fafafa;
}
