

/* Tables
----------------- */

.table {
    th, thead td {
        font-weight: bold;
        background: rgba(0,0,0,0.3);
    }

    td {
        border-bottom: 1px solid $text-color;
        padding: 1em;
    }
    tr:first-child td {
        border-top: 1px solid $text-color;
    }
    tr:nth-child( even ){
        background: rgba(0,0,0,0.1);
    }
    
    a:hover {
        text-decoration: underline;
    }
}
